import LoginForm from "@/components/forms/login-form";
import PaymentLoginForm from "@/components/forms/payment-login-form";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import GuestLayout from "@/layouts/guest-layout";
import { Head } from "@inertiajs/react";

interface Props {
    status?: string;
}

export default function Login({ status }: Props) {
    return (
        <>
            <Head title="Login" />

            {status && (
                <div className="mb-4 font-medium text-sm text-green-600">
                    {status}
                </div>
            )}

            <Tabs defaultValue="account" className="w-[400px]">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="account">My Account</TabsTrigger>
                    <TabsTrigger value="payment">Owner Payment</TabsTrigger>
                </TabsList>

                <TabsContent value="account">
                    <Card>
                        <CardHeader>
                            <CardDescription>
                                Enter your details below to login
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <LoginForm />
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="payment">
                    <Card>
                        <CardHeader>
                            <CardDescription>
                                Enter payment login details to make a payment
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <PaymentLoginForm />
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </>
    );
}

Login.layout = (page: any) => <GuestLayout children={page} />;
